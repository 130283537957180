.searchButton{
    border-right: none;
    border-color: rgb(209, 208, 208);
    box-shadow: none !important;
    background-color: white;
}

.searchButton:hover{
    background-color: white;
    color: rgb(70, 66, 66);
    border-color: rgb(209, 208, 208);
}

.searchField{
    border-left: none;
    box-shadow: none !important;
}
.searchField:focus{
    border-color: rgb(209, 208, 208);
}

.icon{
    display: inline-block;
    cursor: pointer;
}

.header{
    margin-top: 20px;
    font-size: 20px;
}


