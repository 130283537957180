.forgot-wrapper {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
}
.wrapperContainer form {
  padding: 50px 90px;
  margin: 15px;
  background: #fff;
}

.wrapperContainer {
  width: 800px;
  background-color: #fff;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  display: inline-block;
  padding: 25px;
  align-content: center;
}

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mainForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(204, 218, 232) !important;
  border-radius: 5px;
}

.forgetBtn {
  border: hidden;
  color: white;
  background-color: #7a5cfa;
}
.forgetBtn:hover {
  border: hidden;
  color: white;
  background-color: #7054ec;
}

.forgetBtn:focus {
  border: hidden;
  color: white;
  background-color: #7054ec;
}
