.asideRight {
	width: calc(100% - 250px);
	padding: 0px 30px;
	padding-top: 30px;
	transition: ease-out all 0.3s;
}

.asideRight::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../images/dashboard-bg.webp');
    background-repeat: repeat;
    opacity: 0.1;
    z-index: -1;
}

.reportCard {
	min-width: unset;
	width: 100%;
	margin: 0;
}

.textG{
	font-size: 1.1rem;
	color: rgb(97, 85, 85);
}

.textB{
	font-size: 1.4rem;
	font-weight: bold;
	color: #000;
}

@media only screen and (max-width: 1200px) {
	.asideRight {
		padding: 0 20px;
		width: calc(100% - 0px);
	}
}