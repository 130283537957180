.faq {
    margin-top: 20px;
  }
  
  .faq-item {
    margin-bottom: 15px;
  }
  
  .faq-question {
    font-weight: bold;
  }
  
  .faq-answer {
    margin-left: 10px;
  }
  