.itemCard{
	box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 3px 0px;
	margin-top: 10px;
	margin-bottom: 5px;
    text-align: center;
	color: white;
    min-height: 150px;
	width: 100%;
}

.itemCard[disabled]{
	opacity: 1;
}

.info{
    margin-top: 30px;
}

.riskCard{
	width: 350px;
	min-width: 250px;
	display: inline-block;
	margin-right: 0px !important;
    border-radius: 10px;
	color: white;
	margin: 5px;
}

.reportCard{
	min-width: 300px;
	display: inline-block;
	margin-right: 10px !important;
    border-radius: 10px;
	color: white;
	padding: 10px;
	margin: 20px;
}

.heading{
	font-size: 22px;
	
}

.secHeading{
	font-size: 18px;
	padding: 0 10px;
}

.amount{
	font-size: 30px;
}

.growthPosRate{
	font-size: 14px;
	margin-left: 15px;
	color: #5EFF5A;;
}

.growthNegRate{
	font-size: 14px;
	margin-left: 15px;
	color: #f83737;;
}

.desc{
	font-size: 14px;
	color: rgb(202, 199, 199);
	letter-spacing: 1px;
}

.betsDiv{
	margin: 20px;
	padding: 10px;
	border-radius: 5.40px;
	background-color: #3F1578;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
}

.betValue{
	font-size: 24px;
}
