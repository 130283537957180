.login-wrapper {
	width: 100%;
    position: relative;
	display: flex;
    width: 100%;
	min-height: 100vh;
    display: flex;
	align-items: center; 
    min-height: 100vh;
	justify-content: center;
    align-items: center; 
	background-color: #F1EEEF;
    justify-content: center;
	z-index: 1;
    background-color: #F1EEEF;
    z-index: 1;
}

.login-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../images/dashboard-bg.webp');
    background-repeat: repeat;
    opacity: 0.2;
    z-index: -1;
}
.login-wrapper > * {
    position: relative;
    z-index: 2;
}
.login-container {
	display: flex;
	width: 100%;
	max-width: 800px;
	min-height: 600px;
	background-color: #FFF;
	box-shadow: 2px 2px 8px rgba(0,0,0,0.1);
	z-index: 1;
}
.login-left {
	width: 45%;
	color: #fff;
	display: flex;
	position: relative;
	flex-direction: column;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	z-index: 1;
}
.login-left:before {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	background: rgba(0,0,0,0.7);
	z-index: 1;
}
.login-left > div {
	position: relative;
	background-color: rgba(68, 94, 122);
	height: 50%;
	display: flex;
	justify-content: center;
	z-index: 1;
}
.login-left > div h2 { margin-bottom: 15px; }
.login-left > div p { 
	font-size: 14px; 
	max-width: 310px; 
	margin:15px auto 0; 
	text-align: center; 
	z-index: 1;
}
.login-left .top { align-items: flex-end; }
.login-left .bottom { align-items: flex-start; }
.login-right {
	padding: 50px 0;
	width: 55%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
}
.login-right form { width: 80%; color: #999; }

.forgot-wrapper {
	width: 100%;
	display: flex;
	min-height: 100vh;
	align-items: center;
	justify-content: center;
	background-color: #EDEDED;
	z-index: 1;
}
.forgot-wrapper form {
	padding: 50px 90px;
	margin: 15px;
	background: #FFF; 
	max-width: 500px;
	box-shadow: 2px 2px 8px rgba(0,0,0,0.1);
	
}

.toast{
	z-index: 4;
}

.error{
	color: rgb(206, 81, 81);
}

.lgnBtn{
	width: 100px;
    background-color: rgba(68, 94, 122) !important;
	border: 1px solid rgba(68, 94, 122) !important;
    color: white;
}
.lgnBtn:hover{
    background-color: rgba(222, 122, 76) !important;
	border: 1px solid rgba(141, 174, 195) !important;
    color: white;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.copy-right {
	margin-top: 150px;
}