.periodDropdownToggle {
    width: 180px;
    border-radius: 0;
    border-color:  rgb(84, 91, 100);
    background-color: white;
    color: rgb(84, 91, 100);
}

.periodDropdownToggle:hover {
    color: black;
    background-color: white ;
    border-color: black;
}
  
.periodDropdownToggle:focus {
    color: black;
    background-color: white ;
    border-color: black;
}
  
.periodDropdownToggle:active {
    color: black !important;
    background-color: white !important;
    border-color: black !important;
}

.periodDropdownMenu {
    margin: 0px; 
    width: 180px;
}

.periodDropdownItem {
    padding-left: 25px;
}


.periodDropdownToggle::after {
    margin-left: 10px;
}


.periodDropdownToggle span {
    width: 100px;
    display: inline-block; 
}


