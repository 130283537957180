.signup-wrapper {
  width: 100%;
  display: flex;
  min-height: 80vh;
  align-items: center;
  justify-content: center;
}
.signup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  max-width: 900px;
  min-height: 600px;
  background-color: #fff;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.signup-right {
  padding: 50px 0;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup-right form {
  width: 70%;
  color: #999;
}

.forgot-wrapper {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
}
.forgot-wrapper form {
  padding: 50px 90px;
  margin: 15px;
  background: #fff;
  max-width: 500px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.regBtn {
  background-color: #7a5cfa !important;
  border: 1px solid #7a5cfa !important;
  color: white;
}
.regBtn:hover {
  background-color: #5e40e5 !important;
  border: 1px solid #7a5cfa !important;
  color: white;
}

.modalMain {
  padding: 15px !important;
}

.resendBtn {
  border: hidden;
  color: #7a5cfa;
  background-color: white;
  margin-left: 5px !important;
}
.resendBtn:hover {
  border: hidden;
  color: #6241f4;
  background-color: white;
}
.resendBtn:disabled {
  border: hidden;
  color: #6241f4;
  background-color: white;
}

.resendBtn:focus {
  border: hidden;
  color: #6241f4;
  background-color: white;
}

.modal-footer {
  justify-content: space-between;
}

.counter {
  display: inline-block;
}

.viewResendBtn {
  background-color: white;
  border: 1px solid #7a5cfa;
  color: #7a5cfa;
}
.viewResendBtn:hover {
  background-color: #7a5cfa;
  border: 1px solid #7a5cfa;
  color: white;
}
.viewResendBtn:focus {
  background-color: white;
  border: 1px solid #7a5cfa;
  color: #7a5cfa;
}
