.wrapper {
	display: flex;
	min-height: 100vh;
	width: 100% !important;
}
.wrapper:after {
	content: '';
	position: absolute;
	background-color: rgba(0,0,0,0.3);
	width: 100%;
	height: 100%;
	display: none;
}
.open.wrapper:after { display: block; }

.asideLeft {
	width: 250px;
	margin-left: 0px;
	background: #363740;
	transition: ease-out all 0.3s;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: fit-content;
}

.asideLeft .title {
	background-color: #363740;
	color: #fff;
}


.asideLeft ul { list-style-type: none; padding-left: 0; margin-bottom: 0px; }
.asideLeft li {
	position: relative;
}
.asideLeft .side-navigation-panel-select-option  {
	color: #fff;
    display: flex;
	font-size: 18px;
	cursor: pointer;
    align-items: center;
    padding: 15px 20px;
    transition: ease-out all 0.3s;
    border-bottom: #363740 solid 1px;
}
.asideLeft .side-navigation-panel-select-option i { margin-top: 10px; }
.asideLeft .side-navigation-panel-select-option i { margin-right: 10px; }
.asideLeft .side-navigation-panel-select-option:hover  { background-color: #363740; }
.asideLeft .side-navigation-panel-select-option-icon { margin-left: auto; }
.side-navigation-panel-select-inner-option {
    padding: 10px 10px 10px 55px;
    border-bottom: #565656 solid 1px;
    color: #fff;
    background: #343434;
    cursor: pointer;
    transition: ease-out all 0.3s;
}
.side-navigation-panel-select-inner-option:hover {background: #565656;}


.ticketIcon{
	transform: rotate(45deg);
}

.signup {
	margin: 20px 0px;
}