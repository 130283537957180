table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

th {
  border: 1px solid #bcb9b9;
  text-align: left;
  padding: 8px;
}

tr:hover {
  background-color: #e9e7e7;
}

.meta-id-header {
  width: 30%;
}
