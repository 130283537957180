.tabItem{
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-gap: 20px;
    margin-top: 35px;
}

.firstElem{
    margin-left: 10px;
    margin-top: 10px;
}

.secondElem{
    margin-right: 10px;
    font-weight: bolder;
}

.blackText{
    color: black;
}

.greenText{
    color: green;
}

.redText{
    color: red;
}

/* TODO: make the background color match Figma */
.nav-link{
    color: #d3d3d3;
}

.nav-link:focus, .nav-link:hover{
    color: black;
}