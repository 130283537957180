.error {
	width: 100%;
	display: flex;
	min-height: 100vh;
	align-items: center;
	justify-content: center;
	background-color: #EDEDED;
}
.error404 {
	width: 100%;
	padding: 50px;
	display: flex;
	max-width: 500px;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background-color: #FFF;
	box-shadow: 2px 2px 8px rgba(0,0,0,0.1);
}
