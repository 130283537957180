.button{
    width: 150px;
    background-color: #2264E6;
    border-color: #2264E6;
}

.plus-icon{
    font-size: 22px;
    font: bolder;
}

.searchButton{
    border-right: none;
    border-color: rgb(209, 208, 208);
    box-shadow: none !important;
    background-color: white;
}

.searchButton:hover{
    background-color: white;
    color: rgb(70, 66, 66);
    border-color: rgb(209, 208, 208);
}

.searchField{
    border-left: none;
    box-shadow: none !important;
}
.searchField:focus{
    border-color: rgb(209, 208, 208);
}

.icon{
    display: inline-block;
    cursor: pointer;
}

@media only screen and (max-width: 576px){
    .button{
        margin-top: 15px !important;
    }
  }
