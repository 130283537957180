.nav-dropdown {
    display: flex; 
    align-items: center;
    height: 50px;
}

.navbar-nav .nav-item {
    position: relative;
    padding-right:10px; 
}

.navbar-nav .nav-item:not(:last-child) {
    padding-right: 10px; 
}
  
.navbar-nav .nav-item:not(:first-child) {
    padding-left: 10px; 
}
  
.navbar-nav .nav-item:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px; 
    background-color: grey; 
    margin: auto;
    height: 75%;
}

.navbar-nav .nav-item .nav-link {
    color: #fff;
}
  
.navbar-nav .nav-item .nav-link:hover {
    color: orange;
}
  
.navbar-nav .nav-item .nav-link:focus {
    color: orange;
}

.dropdown-divider {
    border-top: 1px solid grey;
}
  
.dropdown-menu {
    border-top: 1px solid grey;
    margin-top: 14px;
}

.nav-dropdown-selected { 
    font-weight: bold;
    color: orange;
}

.dropdown-divider {
    border-top: 1px solid grey;
}
  
.dropdown-menu {
    border: 1px solid grey;
    margin-top: 0px !important;
    border-radius: 0;
}

.nav-dropdown-selected { 
    font-weight: bold;
    color: orange;
}