.asideRight {
	width: 100%;
	padding: 0px 30px;
}

.CircleText{
    margin-top: 15px;
    height: 10px;
    color: rgb(97, 96, 96);
}

.case-info {
    font-size: 1.1em;
    color: #363740;
  }

@media only screen and (max-width: 575px) {
	.CircleText{
        margin-top: 15px;
        color: rgb(202, 199, 199);
        align-self: center;
    }
}
@media only screen and (max-width: 460px) {
	.CircleText{
        margin-top: 15px;
        color: rgb(202, 199, 199);
        align-self: center;
    }
}