.cancelBtn{
    width: 150px;
    background-color: white;
    border: 1px solid #7A5CFA;
    color: #7A5CFA;
}

.cancelBtn:hover{
    width: 150px;
    background-color: white;
    border: 1px solid #7A5CFA;
    color: #7A5CFA;
}

.cancelBtn:focus{
    width: 150px;
    background-color: white;
    border: 1px solid #7A5CFA;
    color: #7A5CFA;
}

.createBtn{
    width: 150px;
    background-color: #7A5CFA !important;
    color: white;
}


.datePicker{
    width: 140px;
    height: 35px;
    border: none;
    outline: none;
    background: #E9EDF5;
    padding: 5px;
}
.datePicker:focus{
    border: none;
    outline: none ;
}

.rightIcon{
    background: #DFDEFC;
    padding-top: 5.5px;
    padding-bottom: 5.5px;
}


