.asideRight {
	width: 100%;
	padding: 0px 30px;
    padding-top: 30px;
}

.nav-link {
    padding: 10px 20px;
}

.nav-link:not(:hover) {
    color: gray;
}