/*@import 'https://fonts.gstatic.com';
@import 'https://fonts.googleapis.com';*/
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Sarina&display=swap";

body {
  font-family: "Roboto", sans-serif !important;
}

.sarina {
  font-family: "PT Serif", serif;
  font-size: 40px;
}
.sarina-small {
  font-family: "PT Serif", serif;
  font-size: 12px;
}
.roboto {
  font-family: "Roboto", sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.title {
  color: #1a1c19;
  margin-bottom: 20px;
}
.App .form-control,
.App .form-select {
  padding: 10px 15px;
  background-color: #f6f6f6;
  border-color: #d4d4d4;
}
.App .form-control::placeholder {
  color: #999;
}
.App .btn-primary {
  color: #fff;
  min-width: 150px;
  padding: 10px 25px;
  border-color: #363740;
  background-color: #363740;
}
.App .btn-primary:hover {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.App .btn-check:focus + .App .btn-primary,
.App .btn-primary:focus {
  color: #fff;
  background-color: #333;
  border-color: #333;
  box-shadow: 0 0 0 0.25rem rgba(51, 51, 51, 0.5);
}
.App a {
  color: #363740;
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
