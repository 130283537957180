.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none;
  background: #363740;
  border-color: unset;
}
.btn-check:focus + .btn-primary,
.btn-primary {
  background-color: #363740;
  border-color: #363740;
}
.btn-check:focus + .btn-primary,
.btn-primary:hover {
  background-color: #363740;
  border-color: #363740;
}

.main-header {
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 10px 0;
  align-items: center;
  position: relative;
  background: #363740;
  height: 50px;
  border-bottom: 1px solid grey;
}
.main-header h2 {
  margin-bottom: 0;
  padding: 0;
}

.toggle-button {
  background: none;
  border-color: #666;
  color: #666;
  display: none;
}

.headerItems {
  display: flex;
  align-items: flex-start;
  align-content: space-between;
  border: 1px solid blue;
}
.accountDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.react-datepicker__navigation {
  top: 8px;
}
#headerDate {
  margin-right: 20px;
  text-align: center;
  width: 12.5rem;
}
.icons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-right: 2px solid rgb(201, 198, 198);
}
.icons i {
  margin-right: 20px;
}
.profile {
  padding-left: 20px;
  display: flex;
  align-items: center;
}
.profile h6 {
  margin-bottom: 0;
}
.profileImg {
  height: 35px;
  width: 35px;
  margin-left: 10px;
  border-radius: 50%;
}

.logoutBtn:hover {
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 9999;
}

@media only screen and (max-width: 800px) {
  .main-header {
    height: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 0;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
    /* border-bottom: #ccc solid 1px;  */
  }
}
@media only screen and (max-width: 800px) {
  .main-header {
    height: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 0;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
    /* border-bottom: #ccc solid 1px;  */
  }
}

@media only screen and (max-width: 575px) {
  .main-header {
    height: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 0;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
    /* border-bottom: #ccc solid 1px;  */
  }
  .profile h6 {
    display: none;
  }
  .icons {
    padding-left: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: none;
  }
  .profile {
    padding-left: 0;
  }
  .profileImg {
    margin-left: 0;
  }
}

@media (max-width: 1200px) {
  .toggle-button {
    left: 0;
    z-index: 999;
    display: block;
    border-radius: 4px;
    position: absolute;
    transition: ease-out all 0.3s;
  }
  .open .toggle-button {
    left: 220px;
    border-radius: 0 4px 4px 0;
    background: #333;
    border-color: #333;
  }
  .main-header h2 {
    padding-left: 60px;
  }
  .asideLeft {
    position: absolute;
    left: -250px;
    height: 100%;
    z-index: 99;
  }
  .open .asideLeft {
    position: absolute;
    left: 0px;
  }
  .asideRight {
    width: 100%;
  }
}
.link.logo img {
  width: 50px;
  height: auto; /* Maintain aspect ratio */
}

.link.logo {
  display: flex; 
  align-items: center;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  padding-left: 15px;
}

